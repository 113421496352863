import App from './App.svelte';
const app = new App({
    target: document.body,
});
const interestingPhrases = ['Another reminder that this part of the page is not important.  Stop looking here', 'This is not something you should be looking at', 'Stop, stop looking here', 'Why must you refuse to heed to my requests', 'This is simply to get the Garlic Bread Time, there is nothing interesting in the console I promise you', 'Look at the page, there is garlic bread ON THE PAGE', 'This page took longer to make than the home page', 'I am looking for a job, if it pays in Garlic Bread please contact me', 'There are no emails at this domain so do not email the web administrator because you will waste your time much like I wasted my time writing this message here', 'A year later or something stupid like that and I quit my job because I hated it and I built this website.', 'This is not an international front for any crimes or anything like that.',
    'I will accept any donations in garlic bread or with garlic bread sauces',
    'Hot sauce is acceptable on garlic bread.  And that is my HOT take',
    'Garlic Bread is life.  Garlic bread is good',
    "I'm not a vegetarian, and I like filet minion which is sort of a guilty pleasure because I have vegetarian leanings. I eat that once in a while, but generally speaking I like to eat vegetarian things. I really like pasta. I really like bread with olive oil and garlic and I like salads. - Author: Jesse Michaels",
    "Can you make the [redacted] while I fix the garlic bread?",
    " I am a 21st century man. I don't believe in magic. I believe in sweat, tears, life [, garlic bread] and death. - Author: Kamal Haasan",
    "Little Ceaser's Crazy Bread is a gift to this earth and if you don't enjoy it you can Super/Meta-Q out of my site.",
    "Garlic Bread > i3wm > Linux > Steak > Cookout w/o a line > \"WTF is up Denny's\" clip",
    'Okay so in order to get into the safe you have to use Garlic Bread',
    'Don\'t type garlic bread on the page',
    'I decided at one point that this was a good idea.  So use your best judgement to determine what you are doing with your existence right now.'];
const size = interestingPhrases.length;
console.log('First of all, if you came here expecting sanity you are in the wrong place');
console.log('Garlic Bread Time is simply about getting the Garlic Bread Time');
console.log('I hacked together the background from https://www.flaticon.com/free-icon/garlic-bread_454603.  Do not sue me I have no money');
console.log('There is no reason this project should have 161+ dependencies but it does and that\'s just something we are all going to have to live with');
setTimeout(() => {
    setInterval(() => {
        const rand = Math.floor(Math.random() * size);
        console.log(interestingPhrases[rand]);
    }, 5000);
}, 5000);
export default app;
