<script lang="ts">
  import {onMount, onDestroy} from 'svelte'
  import Tailwindcss from "./Tailwindcss.svelte";
  let time: string;
  let interval;
  onMount(() => {
    interval = setInterval(() => {
      const now = new Date()
      if(now.getMinutes() === 0) {
        time = 'It is Garlic Bread Time'
      } else if (now.getMinutes() === 1)
      {
        time = `${now.getMinutes()} minute past Garlic Bread Time`
      } 
      else {
        time = `${now.getMinutes()} minutes past Garlic Bread Time`
      }
    }, 1000)
  })
  onDestroy(() => {
    clearInterval(interval)
  })
</script>
<style global>
  html, body {
    background-image: url('../bg.png');
    background-repeat: repeat;
  }
  h1 {
    border: 5px solid #DE9C4B;
    font-family: 'Indie Flower', cursive;
    @apply shadow-md;
  }
</style>
<Tailwindcss />
<div class="min-h-screen grid grid-cols-1 items-center place-content-center ">
  <h1 class="mx-auto w-4/5 lg:w-1/3 text-center bg-white p-5 rounded transition-all duration-150 text-5xl">
    {time ? time : 'Loading'}
  </h1>
</div>